import React from 'react';
import Select from 'react-select';

const SelectFilter = ({ title, options, onChange, currentSelected, isClearable }) => {
  const selectedOption = options.find(option => option.value === currentSelected) || null;

  return (
    <div className="mb-3 filter col-sm-12 col-md-6 col-lg-2">
      <label className="form-label">{title}</label>
      <Select
        options={options}
        value={selectedOption}
        onChange={(selected) => onChange(selected ? selected.value : null)}
        placeholder="Selecione"
        className="basic-single"
        classNamePrefix="select"
        isClearable={isClearable}
      />
    </div>
  );
};

export default SelectFilter;
