import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, Tooltip, Legend, ArcElement } from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(Tooltip, Legend, ArcElement, ChartDataLabels);

function PieChartComponent({ options, onSliceClick }) {
  return (
    <div style={{ width: "300px", height: "90%" }}>
      <Pie data={options.data} options={options.options} />
    </div>
  );
}

export default PieChartComponent;
