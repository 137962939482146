import React from 'react';

const DateRangeSelect = ({ title, startDate, endDate, onChangeStart, onChangeEnd }) => {
  const handleStartChange = (e) => {
    onChangeStart(e.target.value);
  };

  const handleEndChange = (e) => {
    onChangeEnd(e.target.value);
  };

  return (
    <div className="mb-3 row filter col-sm-12 col-md-6 col-lg-4">
      <label className="form-label">{title}</label>
      <div className="row date-range-container">
        <div className="col-6" style={{ display: 'flex', gap: '10px' }}>
          <small className="form-text text-muted">De</small>
          <input
            type="date"
            className="form-control"
            value={startDate || ''}
            onChange={handleStartChange}
          />
        </div>
        <div className="col-6" style={{ display: 'flex', gap: '10px' }}>
          <small className="ms-3 form-text text-muted">Até</small>
          <input
            type="date"
            className="form-control me-3"
            value={endDate || ''}
            onChange={handleEndChange}
          />
        </div>
      </div>
    </div>
  );
};

export default DateRangeSelect;
