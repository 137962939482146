import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend
);

function LineChartComponent({ options }) {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Line data={options.data} options={options.options} />
    </div>
  );
}

export default LineChartComponent;
