import React from "react";
import Box from '@mui/material/Box';
import AssignmentIcon from '@mui/icons-material/Assignment';

import "./CardChartComponent.css";

function CardChartComponent({ title, subtitle, value }) {
  return (
    <div className="card card-chart">
        <div className="card-chart-title-contaier">
            <Box>
            <AssignmentIcon style={{ fontSize: '28px' }} /> {/* Aumente o tamanho aqui */}
            </Box>
            <div className="card-chart-title">{title}</div>
        </div>
        <div className="card-chart-value">
            {value}
        </div>
        <div className="card-chart-subtitle">
            {subtitle}
        </div>
    </div>
  );
}

export default CardChartComponent;
