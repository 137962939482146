import React from 'react';
import './DashboardTabToggle.css'; // Import the CSS file

const DashboardTabToggle = ({ tabs, activeTab, onTabClick }) => {
  return (
    <div className="tab-container">
      {tabs.map((tabName) => (
        <button
          key={tabName}
          onClick={() => onTabClick(tabName)}
          className={`tab-button ${activeTab === tabName ? 'active' : ''}`} 
        >
          {tabName}
        </button>
      ))}
    </div>
  );
};

export default DashboardTabToggle;
