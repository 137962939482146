import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function BarChartComponent({ options, onBarClick }) {
  const handleClick = (event) => {
    const chart = ChartJS.getChart(event.target);
    const points = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, false);

    if (points.length) {
      const firstPoint = points[0];
      const label = chart.data.labels[firstPoint.index];
      onBarClick(label); 
    }
  };

  return (
    <div style={{ width: '100%', height: '100%' }} onClick={handleClick}>
      <Bar data={options.data} options={options.options} />
    </div>
  );
}

export default BarChartComponent;