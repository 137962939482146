import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthProvider";
import DashboardTabToggle from "./DashboardTabToggle";
import BarChartComponent from "./BarChartComponent";
import LineChartComponent from "./LineChartComponent";
import CardChartComponent from "./CardChartComponent";
import DateRangeFilter from "../filters/DateRangeFilter";
import SelectFilter from "../filters/SelectFilter";

import {
  getDashboardData,
  getMostCommonItemData,
  getRegisterPerMonthData,
  getRegisterPerGenderData,
} from "../../services/dashboardService";

import "./Dashboard.css";

import CircularProgress from "@mui/material/CircularProgress";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Box from "@mui/material/Box";
import PieChartComponent from "./PieChartComponent";

function Dashboard() {
  const { userToken } = useAuth();
  const [dashboardData, setDashboardData] = useState(null);
  const [activeTab, setActiveTab] = useState("Doenças");
  const [isLoading, setIsLoading] = useState(false);

  const [currentSelectedDoenca, setCurrentSelectedDoenca] = useState(null);
  const [currentSelectedMedicamento, setCurrentSelectedMedicamento] = useState(null);
  const [currentSelectedProcedimento, setCurrentSelectedProcedimento] = useState(null);
  const [currentStartDate, setCurrentStartDate] = useState(null);
  const [currentEndDate, setCurrentEndDate] = useState(null);

  const [doencaOptions, setDoencaOptions] = useState([]);
  const [medicamentoOptions, setMedicamentoOptions] = useState([]);
  const [procedimentoOptions, setProcedimentoOptions] = useState([]);

  const tabs = ["Doenças", "Medicamentos", "Procedimentos"];

  const fetchDashboardData = async (
    tab = activeTab,
    selectedDoenca,
    selectedMedicamento,
    selectedProcedimento,
    startDateFilter,
    endDateFilter
  ) => {
    setIsLoading(true);
    try {
      const data = await getDashboardData(
        userToken,
        tab,
        selectedDoenca,
        selectedMedicamento,
        selectedProcedimento,
        startDateFilter,
        endDateFilter
      );
      setDashboardData(data);
      updateFilters(data);
    } catch (error) {
      console.error("Erro ao carregar os dados do dashboard:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    fetchDashboardData(tabName);
  };

  const handleBarClick = (item) => {
    cleanFilter(false);

    let selectedDoenca = currentSelectedDoenca;
    let selectedMedicamento = currentSelectedMedicamento;
    let selectedProcedimento = currentSelectedProcedimento;

    if (activeTab === "Doenças") {
      setCurrentSelectedDoenca(item);
      selectedDoenca = item;
    } else if (activeTab === "Procedimentos") {
      setCurrentSelectedProcedimento(item);
      selectedProcedimento = item;
    } else if (activeTab === "Medicamentos") {
      setCurrentSelectedMedicamento(item);
      selectedMedicamento = item;
    }

    fetchDashboardData(
      activeTab,
      selectedDoenca,
      selectedMedicamento,
      selectedProcedimento,
      currentStartDate,
      currentEndDate
    );
  };

  const filter = () => {
    fetchDashboardData(
      activeTab,
      currentSelectedDoenca,
      currentSelectedMedicamento,
      currentSelectedProcedimento,
      currentStartDate,
      currentEndDate
    );
  };

  const cleanFilter = (fetch = true) => {
    setCurrentSelectedDoenca(null);
    setCurrentSelectedMedicamento(null);
    setCurrentSelectedProcedimento(null);
    setCurrentStartDate(null);
    setCurrentEndDate(null);

    if (fetch) {
      fetchDashboardData(activeTab, null, null, null, null, null);
    }
  };

  const updateFilters = (data) => {
    updateFiltersOptions(data);
    setCurrentSelectedDoenca(data.doencaFilter);
    setCurrentSelectedMedicamento(data.medicamentoFilter);
    setCurrentSelectedProcedimento(data.procedimentoFilter);
    setCurrentStartDate(data.startDateFilter);
    setCurrentEndDate(data.endDateFilter);

  };

  const updateFiltersOptions = (data) => {
    const formatOptions = (options) =>
      options.map((option) => ({ value: option, label: option }));

    setDoencaOptions(formatOptions(data.doencaOptions));
    setProcedimentoOptions(formatOptions(data.procedimentoOptions));
    setMedicamentoOptions(formatOptions(data.medicamentoOptions));
  };

  const onFilterChangeDoenca = (newValue) => {
    setCurrentSelectedDoenca(newValue);
  };
  const onFilterChangeMedicamento = (newValue) => {
    setCurrentSelectedMedicamento(newValue);
  };
  const onFilterChangeProcedimento = (newValue) => {
    setCurrentSelectedProcedimento(newValue);
  };
  const onChangeStartDate = (newValue) => {
    setCurrentStartDate(newValue);
  };
  const onChangeEndDate = (newValue) => {
    setCurrentEndDate(newValue);
  };

  const getCardChartPatientsSubtitle = () => {
    let title = "com registro de ";
    const items = [];

    if (dashboardData.doencaFilter !== null) {
      items.push(dashboardData.doencaFilter);
    }
    if (dashboardData.medicamentoFilter !== null) {
      items.push(dashboardData.medicamentoFilter);
    }
    if (dashboardData.procedimentoFilter !== null) {
      items.push(dashboardData.procedimentoFilter);
    }

    if (items.length > 1) {
      title += items.slice(0, -1).join(", ") + " e " + items[items.length - 1];
    } else if (items.length === 1) {
      title += items[0];
    }

    return title;
  };

  const getCardChartTotalQuantitySubtitle = () => {
    console.log(currentSelectedMedicamento);
    if (activeTab === "Doenças") {
      return currentSelectedDoenca !== null
        ? "da doença " + currentSelectedDoenca.toLocaleLowerCase()
        : "da doença";
    } else if (activeTab === "Procedimentos") {
      return currentSelectedProcedimento !== null
        ? "do procedimento " + currentSelectedProcedimento.toLocaleLowerCase()
        : "do procedimento";
    } else if (activeTab === "Medicamentos") {
      return currentSelectedMedicamento !== null
        ? "do medicamento " + currentSelectedMedicamento.toLocaleLowerCase()
        : "do medicamento";
    }
    return "";
  };

  const getRegisterPerGenderPercentage = (index) => {
    if (!dashboardData || !dashboardData.registerPerGender) return "0%";
    const keys = Object.keys(dashboardData.registerPerGender);
    const value = dashboardData.registerPerGender[keys[index]];
    const total = Object.values(dashboardData.registerPerGender).reduce(
      (sum, val) => sum + val,
      0
    );
    if (index < 0 || index >= keys.length || value === 0) {
      return "0%";
    }

    return (Math.round((value / total) * 10000) / 100).toFixed(2) + "%";
  };

  useEffect(() => {
    fetchDashboardData(activeTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!dashboardData) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className="dashboard-main-content">
      {isLoading && (
        <div className="loader-overlay">
          <CircularProgress className="spinner" />
        </div>
      )}

      <div className="dashboard-title-container">
        <h3 className="dashboard-title">Dashboard</h3>
      </div>

      {/* Filters */}
      <div className="card filters-card">
        <div className="filter-title-container">
          <div>
            <h5 className="filter-title">Filtros</h5>
          </div>
          <DashboardTabToggle
            tabs={tabs}
            activeTab={activeTab}
            onTabClick={handleTabClick}
          />
        </div>
        <div className="row filters-container">
          <SelectFilter
            title={"Doença"}
            currentSelected={currentSelectedDoenca}
            options={doencaOptions}
            onChange={onFilterChangeDoenca}
            isClearable={activeTab !== "Doenças"}
          ></SelectFilter>

          <SelectFilter
            title={"Medicamento"}
            currentSelected={currentSelectedMedicamento}
            options={medicamentoOptions}
            onChange={onFilterChangeMedicamento}
            isClearable={activeTab !== "Medicamentos"}
          ></SelectFilter>

          <SelectFilter
            title={"Procedimento"}
            currentSelected={currentSelectedProcedimento}
            options={procedimentoOptions}
            onChange={onFilterChangeProcedimento}
            isClearable={activeTab !== "Procedimentos"}
          ></SelectFilter>

          <DateRangeFilter
            title={"Período"}
            onChangeStart={onChangeStartDate}
            onChangeEnd={onChangeEndDate}
            startDate={currentStartDate}
            endDate={currentEndDate}
          />
          
          <div className="filter-btn-container col-6 col-sm-6 col-md-6 col-lg-1">
            <div className="btn btn-primary filter-btn" onClick={filter}>
              Filtrar
            </div>
          </div>
          <div className="filter-btn-container col-6 col-sm-6 col-md-6 col-lg-1">
            <div className="btn btn-primary filter-btn" onClick={cleanFilter}>
              Limpar
            </div>
          </div>
        </div>
      </div>

      <div className="dashboard-charts">
        {/* Most common graph */}
        <div className="card most-common-graph-card">
          <div className="graph-title-container">
            <Box style={{ color: "#0A91FF" }}>
              <TravelExploreIcon />
            </Box>
            <div className="graph-title">{activeTab} mais frequentes</div>
          </div>
          <BarChartComponent
            options={getMostCommonItemData(dashboardData)}
            onBarClick={handleBarClick}
          />
        </div>

        {/* Quantity graphs */}
        <div className="quantity-graph-card">
          <CardChartComponent
            title={"Total de casos registrados"}
            subtitle={getCardChartTotalQuantitySubtitle()}
            value={dashboardData.totalCasesQuantity}
          />

          <CardChartComponent
            title={"Quantidade de pacientes"}
            subtitle={getCardChartPatientsSubtitle()}
            value={dashboardData.patientsQuantity}
          />
        </div>

        {/* Per month graph */}
        <div className="card time-graph-card">
          <div className="graph-title-container">
            <Box style={{ color: "#5654D4" }}>
              <TroubleshootIcon />
            </Box>
            <div className="graph-title">
              Quantidade de pacientes ao longo do tempo {getCardChartPatientsSubtitle()}
            </div>
          </div>
          <LineChartComponent
            options={getRegisterPerMonthData(dashboardData)}
            onBarClick={handleBarClick}
          />
        </div>

        {/* Per gender graph */}
        <div className="card gender-graph-card">
          <div className="gender-graph-card-content">
            {/* Legend */}
            <div className="gender-graph-legend">
              <div className="graph-title-container mb-3">
                <Box style={{ color: "#5654D4" }}>
                  <ContentPasteSearchIcon />
                </Box>
                <div className="graph-title">Pacientes por gênero</div>
              </div>

              <div className="gender-graph-legend-item-container">
                <div className="gender-graph-legend-item">
                  <Box style={{ color: "#9B55CA" }}>
                    <MoreVertIcon />
                  </Box>
                  <div className="gender-graph-legend-text">Feminino</div>
                </div>
                <div className="gender-graph-legend-percentage">
                  {getRegisterPerGenderPercentage(0)}
                </div>
              </div>
              <div className="gender-graph-legend-item-container">
                <div className="gender-graph-legend-item">
                  <Box style={{ color: "#0A91FF" }}>
                    <MoreVertIcon />
                  </Box>
                  <div className="gender-graph-legend-text">Masculino</div>
                </div>
                <div className="gender-graph-legend-percentage">
                  {getRegisterPerGenderPercentage(1)}
                </div>
              </div>
              <div className="gender-graph-legend-item-container">
                <div className="gender-graph-legend-item">
                  <Box style={{ color: "#83DBC9" }}>
                    <MoreVertIcon />
                  </Box>
                  <div className="gender-graph-legend-text">Não registrado</div>
                </div>
                <div className="gender-graph-legend-percentage">
                  {getRegisterPerGenderPercentage(2)}
                </div>
              </div>
            </div>

            <PieChartComponent
              options={getRegisterPerGenderData(dashboardData)}
              onBarClick={handleBarClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
